import React, { useEffect, useState } from 'react';
import TopBar from '../components/TopBar';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';
import { fetchPlans } from '../API/index'
import { useDispatch, useSelector } from 'react-redux';
import { fetchSeeker } from '../API/actions/seekerAction';
import { SESSION_SEEKER } from '../Utils/Constant';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Pricing = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [plan, setPlan] = useState([])
    const [data, setData] = useState();

    const seeker = useSelector(state => state.seeker.seeker)

    useEffect(() => {
        if (seeker?.plan > 0) {
            // setLogin(true)
            navigate('/')
        } else {
            // setLogin(false)
        }
    }, [seeker])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await fetchPlans();
                setPlan(res?.data?.data)
                console.log('plans', res);
            } catch (error) {
                console.error('Error verifying seeker:');
            }
        };

        fetchData();
    }, [seeker]);

    useEffect(() => {
        setData(JSON.parse(sessionStorage.getItem(SESSION_SEEKER)));
    }, [seeker]);


    useEffect(() => {
        dispatch(fetchSeeker(data?.id))
    }, [dispatch, data, seeker]);

    console.log('seeker', seeker);


    const handlePress = (value, nam, pur, Pname, accounttype, id) => {
        navigate('/checkout', { state: { price: value, name: nam, purpose: pur, Pname: Pname, accounttype: accounttype, id: id } });
    };

    const handleVerifyFirst = () => {
        toast.error('if you want to buy plan please verify first', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
    return (
        <div >
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <section className="text-gray-600 body-font overflow-hidden min-h-screen">
                <div className="container px-5 py-6 pb-10 mx-auto">
                    <div className="flex flex-col text-center w-full mb-10">
                        <h1 className="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">Pricing</h1>
                        <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-gray-500">
                            Upgrade your plan to unlock premium features and find better job opportunities on the website.                        </p>
                    </div>
                    <div className="flex flex-wrap -m-4 justify-center">
                        {plan?.map((item) => (
                            <div key={item.id} className="p-4 xl:w-1/3 md:w-1/2 w-full">
                                <div className="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden transition-transform duration-300 transform hover:scale-105">
                                    <h2 className="text-lg tracking-widest title-font mb-2 font-semibold text-indigo-600">{item.name}</h2>
                                    <h1 className="text-4xl text-gray-900 pb-4 mb-4 border-b border-gray-200 leading-none font-bold">${item.amount}</h1>

                                    <ul className="list-disc text-sm text-gray-600 mt-4 ml-1 mb-20">
                                        {item.purpose?.split(', ').map((sentence, index) => (
                                            <p key={index} className="flex items-center text-gray-600 mb-4">
                                                <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-blue-500 text-white rounded-full flex-shrink-0">
                                                    <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                                                        <path d="M20 6L9 17l-5-5"></path>
                                                    </svg>
                                                </span>{sentence}
                                            </p>
                                        ))}
                                    </ul>
                                    {seeker?.verified === "true" ?
                                        <button onClick={() => handlePress(item.amount, item.name, item.purpose, item.accounttype, item.name, item.id)} className="bg-blue-700 hover:bg-blue-900 text-white border-0 py-2 px-4 w-full m-2 rounded-[1rem] transform transition-all duration-300 hover:scale-105 focus:outline-none absolute bottom-0">
                                            <span className="mr-2">Buy Now</span>

                                        </button>
                                        :
                                        <button onClick={() => navigate('/personalinfo')} className="bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-indigo-500 hover:to-purple-600 text-white border-0 py-2 px-4 w-full rounded-full transform transition-all duration-300 hover:scale-105 focus:outline-none absolute bottom-0">
                                            <span className="mr-2">Verify Account first</span>
                                            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-5 h-5 inline-block align-middle ml-auto">
                                                <path d="M5 12h14M12 5l7 7-7 7"></path>
                                            </svg>
                                        </button>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Pricing;
