import { Modal } from 'flowbite-react'
import { useNavigate } from 'react-router-dom'

const ProAccount = ({ visible, toggleVisible }) => {

    const navigate = useNavigate()

    const handlePress = () => {
        navigate('/pricing')
        toggleVisible()
    }

    return (
        <div>
            <Modal show={visible} size={'4xl'} onClose={() => toggleVisible()}>
                <Modal.Header className='bg-yellow-300 text-white py-4 text-2xl font-[900]'>
                    Buy a plan
                </Modal.Header>
                <Modal.Body className='bg-white p-6'>
                    
                    <div className="w-[20%] m-auto">
                            <img src="./assets/buynow.png" alt="" className="w-160 " />
                    </div>
                    <h2 className='text-3xl font-semibold mt-5 text-center py-0 text-gray-800'>
                        Account is not Activated
                    </h2>
                    <p className='text-lg font-[600] text-center py-0 text-gray-800'>
                        If you want to Distribute your resume. You must buy a plan first.
                    </p>

                    <div className='text-center py-5'>
                        <button onClick={() => handlePress()}
                            type="submit"
                            className='w-[24rem] bg-gradient-to-r from-yellow-400 to-yellow-300 text-white font-bold py-3 px-6 rounded-full hover:shadow-md hover:scale-105 transition duration-300'
                        >
                            Buy a Plan
                        </button>
                    </div>
                    <div className=' mt-[-2rem] text-center py-5'>
                        <button onClick={() => toggleVisible()}
                            type="submit"
                            className='w-[24rem] bg-white text-black border-2 font-bold py-3 px-6 rounded-full hover:shadow-md hover:scale-105 transition duration-300'
                        >
                            Continue Browsing Without Activation
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>

    )
}

export default ProAccount
