import axios from "axios"



const API = axios.create({ baseURL: `${process.env.REACT_APP_API_URL}` })

//Login
export const LoginUser = (email, password) => API.post(`/userAuth/login`,
    {
        email: email,
        password: password
    })

//LoginSeeker
export const LoginSeeker = (email, password) => API.post(`/seekerAuth/login`,
    {
        email: email,
        password: password
    })


// applied jobs =============
export const appliedJobs = (user) => API.post('/applied/user', { user: user })


export const verifySeeker = (id, verified) => API.put(`/seekers/verified`, {
    id: id,
    verified: verified
})

export const EmailRequest = (id) => API.post(`/verifySeekerEmailRequest`, {
    id: id,
    // verified: verified
})

export const GetPlanById = (id) => API.post(`/plans/get`, {
    id: id,
})

export const google = (name, username, email, phone, address, dob, gender, password, type, verified) => API.post(`/seekerAuth/google`, {
    name: name,
    username: username,
    email: email,
    phone: phone,
    address: address,
    dob: dob,
    gender: gender,
    password: password,
    type: type,
    verified: verified
})

//Categories
export const fetchAllCategories = () => API.get(`/categories/all`)
export const fetchFeaturedCategories = () => API.get(`/categories/featured`)

//countries
export const fetchAllCountries = () => API.get(`/countries/all`)

//cities
export const fetchAllCities = () => API.get(`/cities/all`)

//Jobs
export const fetchAllJobs = () => API.post(`/jobs/all`)
export const fetchJobs = () => API.post(`/jobs/jobs`, {
    user: 0
})
export const fetchJob = (id, user) => API.post(`/jobs/get`,
    {
        id: id,
        user: user
    })
export const fetchRecentJobs = () => API.get(`/jobs/recent`)

export const fetchJobSearch = (search, country, category, city, company, salaryStart, salaryEnd, type, isCountry, isCategory, isCity, isCompany, isSalary, isType,) => API.post(`/jobs/search`,
    {
        search: search,
        country: country,
        category: category,
        city: city,
        company: company,
        salaryStart: salaryStart,
        salaryEnd: salaryEnd,
        type: type,
        isCountry: isCountry,
        isCategory: isCategory,
        isCity: isCity,
        isCompany: isCompany,
        isSalary: isSalary,
        isType: isType,
    })
export const fetchJobByCompany = (company) => API.post(`/jobs/company`,
    {
        company: company,
    })
export const fetchJobByCategory = (category) => API.post(`/jobs/category`,
    {
        category: category,
    })

//Categories
export const fetchAllCompanies = () => API.get(`/companies/all`)
export const fetchCompany = (id) => API.post(`/companies/get`, {
    id: id,
})

//Seekers
export const fetchSeekerRecommended = (job) => API.post(`/seekers/recommended`, {
    job: job,
})
export const updateSeeker = (name, city, country, username, code, phone, address, dob, gender, id) => API.put('/seekers/update', {
    name: name,
    city: city,
    country: country,
    username: username,
    code: code,
    phone: phone,
    address: address,
    dob: dob,
    gender: gender,
    id: id
})
export const fetchSeeker = (id) => API.post('/seekers/get', { id: id })

//CV
export const fetchCvByUser = (user) => API.post(`/cv/user`, {
    user: user,
})

//BOOKMARKS
export const fetchBookmarks = (user) => API.post('/bookmarks/all', { user: user })
export const bookmarkJob = (job, user) => API.post('/bookmarks/add', { job: job, user: user })
export const removeBookmark = (id) => API.delete('/bookmarks/remove', { data: { id: id } })

//ApiJobs
export const fetchApiJobs = (search) => API.post(`/apiJobs/jobs`, {
    search: search,
})
export const fetchApiJobsRecent = (search) => API.post(`/apiJobs/recent`, {
    search: search,
})
export const fetchFeaturedJob = (id) => API.post(`/apiJobs/job`, {
    id: id,
})


export const register = (name, username, email, phone, address, dob, gender, password, type) => API.post(`/seekerAuth/register`, {
    name: name,
    username: username,
    email: email,
    phone: phone,
    address: address,
    dob: dob,
    gender: gender,
    password: password,
    type: type
})

export const registerProvider = (name, size, city, country, email, phone, address, headquater, type, password, account) => API.post('/providerAuth/register', {
    name: name,
    size: size,
    city: city,
    country: country,
    email: email,
    phone: phone,
    address: address,
    headquater: headquater,
    type: type,
    password: password,
    account: account
})


// resume ===================

export const addCVCareer = (cv, company, job, timeperiod, address, phone) => API.post('/cvCareer/create', {
    cv: cv,
    company: company,
    job: job,
    timeperiod: timeperiod,
    address: address,
    phone: phone
})

export const checkCV = (user) => API.post('/cv/check', { user: user })

export const updateCVCareer = (cv, company, job, timeperiod, address, phone, id) => API.put('/cvCareer/update', {
    cv: cv,
    company: company,
    job: job,
    timeperiod: timeperiod,
    address: address,
    phone: phone,
    id: id
})
export const deleteCVCareer = (id) => API.delete('/cvCareer/delete', { data: { id: id } })

export const addCVCourse = (cv, course, timeperiod, institute) => API.post('/cvCourse/create', {
    cv: cv,
    course: course,
    timeperiod: timeperiod,
    institute: institute
})
export const updateCVCourse = (cv, course, timeperiod, institute, id) => API.put('/cvCourse/update', {
    cv: cv,
    course: course,
    timeperiod: timeperiod,
    institute: institute,
    id: id
})
export const deleteCVCourse = (id) => API.delete('/cvCourse/delete', { data: { id: id } })

export const addCVEducation = (cv, qualification, timeperiod, institute) => API.post('/cvEducation/create', {
    cv: cv,
    qualification: qualification,
    timeperiod: timeperiod,
    institute: institute
})
export const updateCVEducation = (cv, qualification, timeperiod, institute, id) => API.put('/cvEducation/update', {
    cv: cv,
    qualification: qualification,
    timeperiod: timeperiod,
    institute: institute,
    id: id
})
export const deleteCVEducation = (id) => API.delete('/cvEducation/delete', { data: { id: id } })

export const addCVSkill = (cv, skill) => API.post('/cvSkill/create', {
    cv: cv,
    skill: skill
})
export const updateCVSkill = (cv, skill, id) => API.put('/cvSkill/update', {
    cv: cv,
    skill: skill,
    id: id
})
export const deleteCVSkill = (id) => API.delete('/cvSkill/delete', { data: { id: id } })

export const addCVInterest = (cv, interest) => API.post('/cvInterest/create', {
    cv: cv,
    interest: interest
})
export const updateCVInterest = (cv, interest, id) => API.put('/cvInterest/update', {
    cv: cv,
    interest: interest,
    id: id
})
export const deleteCVInterest = (id) => API.delete('/cvInterest/delete', { data: { id: id } })

export const addCVLanguage = (cv, language) => API.post('/cvLanguage/create', {
    cv: cv,
    language: language
})
export const updateCVLanguage = (cv, language, id) => API.put('/cvLanguage/update', {
    cv: cv,
    language: language,
    id: id
})
export const deleteCVLanguage = (id) => API.delete('/cvLanguage/delete', { data: { id: id } })

export const createCoverLetter = (user, job, date, role, intro, body) => API.post('/cover/create', {
    user: user,
    job: job,
    date: date,
    role: role,
    intro: intro,
    body: body
})

export const applyJob = (job, user, date, proposal) => API.post('/applied/create', {
    job: job,
    user: user,
    date: date,
    proposal: proposal,

})

export const roleUpdate = (role, id) => API.put('/seekers/role', {
    role: role,
    id: id
})
export const cvStatement = (id, statement) => API.post('/cv/statement', {
    id: id,
    statement: statement
})
export const fetchPlans = () => API.get(`/plans/seeker`)
export const createUserPlan = (user, plan, activation_date, user_type) => API.post('/userPlans/create', {
    user: user,
    plan: plan,
    activation_date: activation_date,
    user_type: user_type
})

export const distributeResume = (data) => API.post(`/distribute`, {
    name: data.name,
    address: data.address,
    phone: data.phone,
    code: data.code,
    email: data.email,
    role: data.role,
    intro: data.statement,
    skills: data.skills,
    careers: data.careers,
    educations: data.educations,
    courses: data.courses,
    interests: data.interests
})