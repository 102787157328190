// Checkout.jsx
import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './Payment';
import TopBar from '../components/TopBar';
import Footer from '../components/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSeeker } from '../API/actions/seekerAction';
import { SESSION_SEEKER } from '../Utils/Constant';
const stripePromise = loadStripe('pk_live_51JyEXWJ1pOdYlLIldJdoClXliI5xTV7prN0KHFTcmBDLhVkgKv8JczXgC86POetKDsxD2lZUF2XUxfZovR1SrCbG00NNnrZQwF');

const Checkout = () => {

    const dispatch = useDispatch()

    const location = useLocation();
    const price = parseInt(location?.state?.price) * 100;

    const [data, setData] = useState()
    const navigate = useNavigate()

    const seeker = useSelector(state => state.seeker.seeker)

    useEffect(() => {
        if (seeker?.plan > 0) {
            // setLogin(true)
            navigate('/')
        } else {
            // setLogin(false)
        }
    }, [seeker])

    useEffect(() => {
        if (!data) {

            setData(JSON.parse(sessionStorage.getItem(SESSION_SEEKER)));
        }
    }, [data, seeker]);



    useEffect(() => {
        if (!seeker) {
            dispatch(fetchSeeker(data?.id))
        }
    }, [dispatch, data, seeker]);


    const [client, setClient] = useState(null)
    const [options, setOptions] = useState()

    const fetchPaymentIntentClientSecret = async () => {
        const response = await fetch(process.env.REACT_APP_API_URL + `/create-payment-intent`, {
            method: "POST",
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                price: price,
            })
        });
        const { clientSecret, error } = await response.json();
        setClient(clientSecret)
        return { clientSecret, error };
    };

    useEffect(() => {
        fetchPaymentIntentClientSecret()
    }, []);


    useEffect(() => {
        if (client) {
            setOptions({
                clientSecret: client
            })
        }
    }, [client]);

    return (
        <div className="min-h-screen flex flex-col">
            <div className="flex-grow">
                <div>
                    {options ?
                        <Elements stripe={stripePromise} options={options}>
                            <PaymentForm clientSecret={client} />
                        </Elements>
                        : ''}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Checkout;
